
  import common from '@mixins/common'
  import filters from '@filters/filters'
  import { formatPx2Rem } from '@utils/index'
  import { isEmpty, dateFilter } from '@utils/utils'
  import { statusMap } from '@/dictionaries/checkOut'
  import { getHistoryAPI, submitCheckOutApplyAPI, getCheckOutReasonApi } from '@api/checkOut'
  import { mapGetters } from 'vuex'
  import { debounce } from 'lodash'

  export default {
    name: 'CheckOutApply',
    mixins: [common, filters],
    components: {},
    data() {
      return {
        headerTitle: '代客退房申请',
        statusMap,
        isLoading: false, // 是否在加载数据
        isRefreshPage: false, // 是否需要刷新页面
        noDataMessage: '', // 列表为空时的提示文案
        detailInfo: {}, // 详情信息
        roomList: [],
        showRoom: true,
        roomSearchText: '',
        roomNo: '',
        roomUser: '',
        roomUserDate: '',
        contractId: '',
        mobile: '',
        bankName: '',
        bankAccount: '',
        bankDeposit: '',
        type: '2',
        reasonSearchValue: '',
        checkOutReasonValue: {},
        customReason: '',
        checkOutReasonList: [],
        show1: false,
        show2: false,
        show3: false,
        show4: false,
        date1: '',
        date2: '',
        minDate1: new Date(),
        maxDate1: new Date(2099, 12, 31),
        minDate2: new Date(),
        maxDate2: new Date(2099, 12, 31),
        currentDate1: new Date(),
        currentDate2: new Date()
      }
    },
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ]),
      filterCheckOutReason() {
        return this.checkOutReasonList.filter((item) => {
          return item.text && item.text.toLowerCase().includes(this.reasonSearchValue.toLowerCase())
        })
        // return this.checkOutReasonList.filter((item) => item.text && item.text.includes(this.reasonSearchValue))
      }
    },
    methods: {
      formatPx2Rem,
      // 日期格式化
      dateFilter,
      // 空字段过滤
      fieldEmptyFilter(data, returnEmpty = '无') {
        return !isEmpty(data) ? data : returnEmpty
      },
      // 字母数字过滤
      formatterField(value) {
        return value.replace(/[^\u4E00-\u9FA5]/g, '')
      },
      // 只能输入数字字母
      formatterField2(value) {
        return value.replace(/[^\w\.\/]/ig, '')
      },
      async handleSearchRoom({ isShowLoading } = { isShowLoading: true }) { // 完成接待
        if (!this.roomSearchText) {
          this.$toast('请输入房号')
          return
        }
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let { roomSearchText } = this
          let { projectId } = this.projectInfo
          let response = await getHistoryAPI({
            projectId,
            roomNo: roomSearchText
          })
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            // 接口请求处理成功之后回跳
            this.roomList = result.data
            this.showRoom = true
            if (this.roomList.length == 0) {
              this.$toast('无搜索结果')
            }
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      handleSelectRoom(room) {
        this.roomSearchText = room.roomNo
        this.roomNo = room.roomNo
        this.roomUser = room.userName
        this.roomUserDate = room.startDate + '~' + room.endDate
        this.contractId = room.contractId
        this.mobile = room.mobile
        this.showRoom = false
      },
      hourFilter(type, options) {
        if (type === 'hour') {
          // 验房时间是早9晚6
          return options.filter((option) => ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18'].includes(option))
        }
        return options
      },
      onConfirm1(date) {
        this.show1 = false
        this.date1 = this.dateFilter(date, '-', 'YYYY-MM-DD HH:mm')

        const newDate = date.setHours(date.getHours() - 1)
        this.date2 = this.dateFilter(newDate, '-', 'YYYY-MM-DD HH:mm')

        // this.date2 =
        // this.minDate2 = new Date(this.date1.slice(0, -6))
        // this.maxDate2 = new Date(this.date1)
      },
      onConfirm2(date) {
        // if (!this.date1) {
        //   this.$toast('请先选择离店时间')
        //   return
        // }
        this.show2 = false
        this.date2 = this.dateFilter(date, '-', 'YYYY-MM-DD HH:mm')
      },
      handleApply: debounce(function() {
        if (!this.roomNo) {
          this.$toast('请选择房号')
          return
        }
        if (!this.bankName) {
          this.$toast('请输入账号名')
          return
        }
        const bankNameReg = /[^\u4E00-\u9FA5]/
        if (bankNameReg.test(this.bankName)) {
          this.$toast('账号名只能输入中文')
          return
        }
        if (!this.bankAccount) {
          this.$toast('请输入账号')
          return
        }
        const bankAccountReg = /[^\w\.\/]/
        if (bankAccountReg.test(this.bankAccount)) {
          this.$toast('账号只能输数字及英文字母')
          return
        }
        if (!this.bankDeposit) {
          this.$toast('请输入开户行')
          return
        }
        const bankDepositReg = /[^\u4E00-\u9FA5]/
        if (bankDepositReg.test(this.bankDeposit)) {
          this.$toast('开户行只能输入中文')
          return
        }

        if (!this.checkOutReasonValue.tagName) {
          this.$toast('请选择退房原因')
          return
        }
        if (!this.date1) {
          this.$toast('离店时间不能为空')
          return
        }
        if (!this.date2) {
          this.$toast('上门验房时间不能为空')
          return
        }
        if (new Date(this.date1).getTime() < new Date(this.date2).getTime()) {
          this.$toast('离店时间不能早于验房时间')
          return
        }
        this.$dialog.confirm({
          title: '提示',
          message: '是否确认？',
          confirmButtonColor: '#D8B084'
        }).then(async () => {
          // on confirm
          await this.submitCheckOutApplyData()
        }).catch(() => {
          // on cancel
        })
      }, 200),
      async submitCheckOutApplyData({ isShowLoading } = { isShowLoading: true }) { // 完成接待
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let response = await submitCheckOutApplyAPI({
            contractId: this.contractId,
            checkTime: this.date2,
            leaveTime: this.date1,
            roomNo: this.roomNo,
            userName: this.roomUser,
            mobile: this.mobile,
            bankName: this.bankName,
            bankAccount: this.bankAccount,
            bankDeposit: this.bankDeposit,
            checkOutType: this.type,
            reasonList: [this.checkOutReasonValue]
          })
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            // 接口请求处理成功之后回跳
            this.$toast({
              message: '提交成功！',
              onClose: () => {
                this.$router.go(-1)
              }
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async handleCallback({
        isError,
        errorMessage = undefined
      }) { // 回调处理
        if (isError) {
          this.$toast(errorMessage || '获取失败!')
          /* if (errorMessage && errorMessage.includes('timeout')) {
            // 判断是否是网络请求超时
            this.isRefreshPage = true
            this.noDataMessage = '网络请求超时'
          } else if (errorMessage && errorMessage.includes('502')) {
            // 判断是否是服务器错误
            this.isRefreshPage = true
            this.noDataMessage = '服务器错误，502错误'
          } */
        }
      },
      async initData() {
      },

      // 退房原因
      onTypeChange() {
        this.checkOutReasonValue = {}
      },
      handleOpenCheckOutReason() {
        this.$store.dispatch('base/SetLoading', true)
        getCheckOutReasonApi({ type: this.type }).then((res) => {
          this.checkOutReasonList = res.data.data.map((item) => {
            if (item.isCustom === 1) {
              return {
                id: item.id,
                isCustom: item.isCustom,
                text: item.tagName + "(自定义原因)",
                tagName: item.tagName
              }
            } else {
              return {
                id: item.id,
                isCustom: item.isCustom,
                text: item.tagName,
                tagName: item.tagName
              }
            }
          })
          this.show3 = true
        }).finally(() => {
          this.$store.dispatch('base/SetLoading', false)
        })
      },
      handleCheckoutReasonConfirm(value, index) {
        this.checkOutReasonValue = value
        this.show3 = false
        // 备注
        if (value.isCustom === 1) {
          this.show4 = true
        }
      },
      handleCheckOutReasonCancel() {
        this.show3 = false
      },
      onBeforeClose(action, done) {
        if (action === 'confirm') {
          // 确认
          if (!this.customReason) {
            this.$toast('请输入原因')
            done(false)
          } else {
            this.checkOutReasonValue.customReason = this.customReason
            done(true)
          }
        } else {
          // 取消
          this.checkOutReasonValue = {}
          this.customReason = ''
          done(true)
        }
      }
    },
    filters: {},
    watch: {},
    created() {
      const { token, projectId } = this.$route.query
      if (token && projectId) {
        this.$store.commit('user/SET_TOKEN', token)
        this.$store.commit('user/SET_USER_INFO', { projectId })
        this.$store.commit('user/SET_PROJECT_INFO', { projectId })
      }
    },
    mounted() {
      this.initData()
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
